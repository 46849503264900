
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as faq9HOSiuSYDJYLUf_45gUPXD2bRkk1d23YPmIZogO0b8KIUMeta } from "/codebuild/output/src3218880673/src/src/pages/faq.vue?macro=true";
import { default as menu0zwxtjzBhQsI3TUX0EWIv2U3j86s5cJLdqKTTThtqm4Meta } from "/codebuild/output/src3218880673/src/src/pages/menu.vue?macro=true";
import { default as index2xQ4VDVkEIBE8n5QzgGtkuLaiPkrCKYeLmaFV9DErbwMeta } from "/codebuild/output/src3218880673/src/src/pages/index.vue?macro=true";
import { default as contactnMhx0seQC13GdMCXyQm2Phg3rKCTz7XRhrF5Qc2wn0AMeta } from "/codebuild/output/src3218880673/src/src/pages/contact.vue?macro=true";
import { default as tutorialK7uSqwqWhTnlpYN2kGzjvkfRiVY5PyAIqbFGrvZabKYMeta } from "/codebuild/output/src3218880673/src/src/pages/tutorial.vue?macro=true";
import { default as agreementS6lRIw4yrktHPv9z8qAhsYUOQVjdsoXBrGmXgXb_OEkMeta } from "/codebuild/output/src3218880673/src/src/pages/agreement.vue?macro=true";
import { default as about_45pass5UoSsPICBqU86iOX8UldpaO8qlrYCZXQCSNFhXQFyz8Meta } from "/codebuild/output/src3218880673/src/src/pages/about-pass.vue?macro=true";
import { default as indexs3aguvfiETsrrccCujUHlZsTU4_5aUkQCpk5ctz7IXwMeta } from "/codebuild/output/src3218880673/src/src/pages/card/index.vue?macro=true";
import { default as _91id_93WkITJkCuZPwCln1T1Xgy7RCjN6YLUfZ_45mC2u37THpwIMeta } from "/codebuild/output/src3218880673/src/src/pages/coupon/[id].vue?macro=true";
import { default as maintenanceFo7769xv72f4AQnZ5B8AE51Mhv9AY_bATjLxPduHNHMMeta } from "/codebuild/output/src3218880673/src/src/pages/maintenance.vue?macro=true";
import { default as _91id_93VYKUj2hmlsNT2sMNA31Ju9j2gnS4VIgExQt_45IdYgLNgMeta } from "/codebuild/output/src3218880673/src/src/pages/notice/[id].vue?macro=true";
import { default as indexdn6fIm5yrxzRxX2_45mIETeeyR79yMPjNglVlklhvYeLsMeta } from "/codebuild/output/src3218880673/src/src/pages/coupon/index.vue?macro=true";
import { default as indexjrpLuKIUim7XIo34JzIPR_45hKRSbjS68qBsRVAD6suoAMeta } from "/codebuild/output/src3218880673/src/src/pages/mypage/index.vue?macro=true";
import { default as indexiLYKwE6rHgbANUThZUG3ZJFar9_vdWsHtUqrDelWJDcMeta } from "/codebuild/output/src3218880673/src/src/pages/notice/index.vue?macro=true";
import { default as _91id_93pFDGXjpQNJ0niWn9j_458HioPobGECfbT6rqPxeJ_45NpLwMeta } from "/codebuild/output/src3218880673/src/src/pages/parking/[id].vue?macro=true";
import { default as indexxTjBrK8Wd3vmTI82XMGpHIfiEYXyuo1KHi_111rWxJcMeta } from "/codebuild/output/src3218880673/src/src/pages/history/index.vue?macro=true";
import { default as indexQZ3MxADR12V9J4EjRYkUyVwl83xnQEr8Y_Izbtw32z4Meta } from "/codebuild/output/src3218880673/src/src/pages/sign-in/index.vue?macro=true";
import { default as indexaA74HXRlb8PkfLw5BwBXdkCOUS0tFvPur_45c8b5VmCUYMeta } from "/codebuild/output/src3218880673/src/src/pages/sign-up/index.vue?macro=true";
import { default as about_45yumenaviVHliO1u0G_n0q1ux1bWNp6w9y7zZ_45fPKgQA28SpTUbIMeta } from "/codebuild/output/src3218880673/src/src/pages/about-yumenavi.vue?macro=true";
import { default as editgzm412tAYVpRw3Yz4UHuRjCEH8zy30HNqJu_20Wu7C4Meta } from "/codebuild/output/src3218880673/src/src/pages/card/[id]/edit.vue?macro=true";
import { default as pass_45agreementk1k2Qb7xQ0JWPFa4B54zMAICih_45BNuWAe09HrNfGpTYMeta } from "/codebuild/output/src3218880673/src/src/pages/pass-agreement.vue?macro=true";
import { default as indexnQcHncdtHB1ZqSqhx5vthK6tgY8m9G5x088aP7EyZgQMeta } from "/codebuild/output/src3218880673/src/src/pages/card/[id]/index.vue?macro=true";
import { default as confirmPcj0sIhh_45wai_mAlYfU_45QxhWxITWsargWYZCPZuYOvgMeta } from "/codebuild/output/src3218880673/src/src/pages/card/[id]/confirm.vue?macro=true";
import { default as indexESJotP13XX4ptOg4x3vFz4rHmMU64NCZ2Zj2X_QU90wMeta } from "/codebuild/output/src3218880673/src/src/pages/card/create/index.vue?macro=true";
import { default as indexjpI6mgbf5IU2kH5U6LtwZzTlr53t6aYpewXfV0_45swEsMeta } from "/codebuild/output/src3218880673/src/src/pages/mypage/pass/index.vue?macro=true";
import { default as confirmRkaVjuOf1_457VAXnCFYC9Z06LemUlhrAQD75Wsorgo64Meta } from "/codebuild/output/src3218880673/src/src/pages/membership/confirm.vue?macro=true";
import { default as confirm1y0OjPF4QPTgEHpS5J0DwAYzZPiCDMqFMFSpoLJwZDEMeta } from "/codebuild/output/src3218880673/src/src/pages/card/create/confirm.vue?macro=true";
import { default as completeRl9GqGtvvgFCFfuzfHyzabVesjyYmyU6Fqi_LEEy6JwMeta } from "/codebuild/output/src3218880673/src/src/pages/membership/complete.vue?macro=true";
import { default as registerHGQkuOHYp_MRvjwnWJeKLM_45EtcHXl3rbaETMFC_45PO8UMeta } from "/codebuild/output/src3218880673/src/src/pages/membership/register.vue?macro=true";
import { default as _91parkingId_93aLz8dTlbbX3RIA_45nrmf6pvXJv6PHSDm2hQUyu_45zRo5IMeta } from "/codebuild/output/src3218880673/src/src/pages/monthly/[parkingId].vue?macro=true";
import { default as confirmyMxc9v3fyOZLb0xdb0indLCfMNvO17G3m_xIWOmv6IcMeta } from "/codebuild/output/src3218880673/src/src/pages/contact-form/confirm.vue?macro=true";
import { default as _91id_930fTHwoDBTv4Y0scBUg4KXwMoa5C_1wHtFDg_unOVJ6UMeta } from "/codebuild/output/src3218880673/src/src/pages/history/receipt/[id].vue?macro=true";
import { default as confirm_45codexoaxz4KJ9yznq0bUNrMZ1U9fjIgbZGP0Hhc1WHxtkQsMeta } from "/codebuild/output/src3218880673/src/src/pages/sign-in/confirm-code.vue?macro=true";
import { default as confirm_45codekvrh7mR5iSX7aewVcIxbU_45Dq_45vvN09iELNOolJ2iyFoMeta } from "/codebuild/output/src3218880673/src/src/pages/sign-up/confirm-code.vue?macro=true";
import { default as completeewfumv2iry52QE1djYuW4nuUBg6uLL1iDVlR_gm2vckMeta } from "/codebuild/output/src3218880673/src/src/pages/contact-form/complete.vue?macro=true";
import { default as registerILTgEMUNLqpjA0UiVMFRn4dCz1MIQal2bVCGCvMSl18Meta } from "/codebuild/output/src3218880673/src/src/pages/contact-form/register.vue?macro=true";
import { default as index8_hgA1gV_v9UodKMzUrsmjQK8kec70CtjfnMhfAHzcEMeta } from "/codebuild/output/src3218880673/src/src/pages/mypage/membership/index.vue?macro=true";
import { default as confirmzoQFnCjve8ilHqvw_PSnySRmh7inKvSWVcENKHO4ATEMeta } from "/codebuild/output/src3218880673/src/src/pages/mypage/sign-out/confirm.vue?macro=true";
import { default as complete_456f2mdd_LSiQIb3QjUaIPn4ESI7HlUfsoFJNYj0o72wMeta } from "/codebuild/output/src3218880673/src/src/pages/reset-password/complete.vue?macro=true";
import { default as indexyyn4wb4IAckioCUoWi9wNHsKFXLF6RRinwgl6kIaBX8Meta } from "/codebuild/output/src3218880673/src/src/pages/mypage/integration/index.vue?macro=true";
import { default as _91id_93_QXE9_45nuqCvqx_GgQSVin4IYrLZ_4530Wtp3CXa2ysxpQMeta } from "/codebuild/output/src3218880673/src/src/pages/mypage/pass/receipt/[id].vue?macro=true";
import { default as completeBuc2InQkhiIGgMhksRNT7HHI30hbkIautmGjH4tE1tQMeta } from "/codebuild/output/src3218880673/src/src/pages/mypage/sign-out/complete.vue?macro=true";
import { default as edit_45card_45aliasDng5jyLv46zhLbg2lZaYiOvEW6eAG5P_Gg9q7F_opkMMeta } from "/codebuild/output/src3218880673/src/src/pages/card/[id]/edit-card-alias.vue?macro=true";
import { default as sampleWLyhjbSUoxvJlkFBoWROBdwgB2dYE3Kavo4eihp51WUMeta } from "/codebuild/output/src3218880673/src/src/pages/mypage/integration/sample.vue?macro=true";
import { default as send_45emailPDUBWuPEh_45nq7MBEYnpCtNuXN0tOpME1W4V7TqRig_MMeta } from "/codebuild/output/src3218880673/src/src/pages/reset-password/send-email.vue?macro=true";
import { default as confirmMnPK7fYnSN3x_5_45TnOCkoZGq_Y_1h59Sjb4JnTezT3cMeta } from "/codebuild/output/src3218880673/src/src/pages/mypage/unsubscribe/confirm.vue?macro=true";
import { default as consenteMUuanPIFT0XfMRhSYKWTx2lwjPNXQXO0pQ3kSxUDpkMeta } from "/codebuild/output/src3218880673/src/src/pages/mypage/unsubscribe/consent.vue?macro=true";
import { default as confirmlKcCfo99zi3HO0ME2VsflFB3nWgkTlb_45uYVdeWVaLFAMeta } from "/codebuild/output/src3218880673/src/src/pages/membership/yumenavi/confirm.vue?macro=true";
import { default as completebPiV0YLGRNhNWjXDvkpbKxvlE7ILPHl6dYeAggdLNEYMeta } from "/codebuild/output/src3218880673/src/src/pages/mypage/unsubscribe/complete.vue?macro=true";
import { default as confirm_45codeTAvBiqxW6fJxOgrATgfW4lsknUQDveM19JBE7SgFW5sMeta } from "/codebuild/output/src3218880673/src/src/pages/reset-password/confirm-code.vue?macro=true";
import { default as new_45passwordZGh6SjSZMtUDjMy5lJlGvGaEUWZhA2ROHmd4MeWkXMcMeta } from "/codebuild/output/src3218880673/src/src/pages/reset-password/new-password.vue?macro=true";
import { default as confirm_45card_45aliasyXhiYnylp68N403llqLbRtdpEllEB7T5BtVwaLhuDoUMeta } from "/codebuild/output/src3218880673/src/src/pages/card/[id]/confirm-card-alias.vue?macro=true";
import { default as registerNqylVWjC2AqRwy6_45Eaxx7lfaDHfj1e1ABaW5JfRSQiUMeta } from "/codebuild/output/src3218880673/src/src/pages/membership/yumenavi/register.vue?macro=true";
import { default as confirm_45code4B_8VQrFjshLO4Ohp1nIwYFmheLXcen53PxKSc0nctcMeta } from "/codebuild/output/src3218880673/src/src/pages/membership/yumenavi/confirm-code.vue?macro=true";
import { default as index5_45bIlc_450_N1Ox94FpXWeTsJYgh9IV0RPeYK5h_45D2EgAMeta } from "/codebuild/output/src3218880673/src/src/pages/mypage/membership/email-edit/index.vue?macro=true";
import { default as indexVCQtJkohcUgr04olyXrAF6lNGTDictlMLUzvDDLLyPoMeta } from "/codebuild/output/src3218880673/src/src/pages/mypage/membership/others-edit/index.vue?macro=true";
import { default as update2lCNxFp70OtPKdcdFiMYnmp79jvhxQFvdeGvdTAEcvgMeta } from "/codebuild/output/src3218880673/src/src/pages/mypage/membership/others-edit/update.vue?macro=true";
import { default as completeFEG2RDmF93TxxB38awkJolrbU1_45s2yWgoL_45Jlo2j5C0Meta } from "/codebuild/output/src3218880673/src/src/pages/mypage/membership/email-edit/complete.vue?macro=true";
import { default as confirmQgDNzz_45ogkrThZPJyCXgGTCX_45yLNNKPrjuJ_zmi_bd8Meta } from "/codebuild/output/src3218880673/src/src/pages/mypage/membership/others-edit/confirm.vue?macro=true";
import { default as completeFHV59G2Hwfitdeltgv1X3PP_dY4tbIPhf72ejxrm6fAMeta } from "/codebuild/output/src3218880673/src/src/pages/mypage/membership/others-edit/complete.vue?macro=true";
import { default as updatew6m_CusRJV6Tva6BT9QUJWiQTwyyUVK_ZKhEZtONdO4Meta } from "/codebuild/output/src3218880673/src/src/pages/mypage/membership/password-edit/update.vue?macro=true";
import { default as completesUolqHriru09K5krmL1nmITyk7NX1ZAvc19kFFOmEg0Meta } from "/codebuild/output/src3218880673/src/src/pages/mypage/membership/password-edit/complete.vue?macro=true";
import { default as confirm_45codeYSMgXYp814u0EP_452Tk92C6RmrQcarPhSEgZ1KG7QaW4Meta } from "/codebuild/output/src3218880673/src/src/pages/mypage/membership/email-edit/confirm-code.vue?macro=true";
import { default as index8_cotrNdx0TQDMeBAqb7sKX_GijSFutIJQ2KpmSybhMMeta } from "/codebuild/output/src3218880673/src/src/pages/mypage/membership/others-edit/yumenavi/index.vue?macro=true";
import { default as updatetHVHtgqxfaFmrZ0pD7o2aBlQo_45DeWOfjxwJfLpXYf8UMeta } from "/codebuild/output/src3218880673/src/src/pages/mypage/membership/others-edit/yumenavi/update.vue?macro=true";
import { default as confirmRV1K3ulvdF7dxygTfYCwp99ItN7nNkLEjSlmIi35cdsMeta } from "/codebuild/output/src3218880673/src/src/pages/mypage/membership/others-edit/yumenavi/confirm.vue?macro=true";
export default [
  {
    name: "faq",
    path: "/faq",
    component: () => import("/codebuild/output/src3218880673/src/src/pages/faq.vue")
  },
  {
    name: "menu",
    path: "/menu",
    meta: menu0zwxtjzBhQsI3TUX0EWIv2U3j86s5cJLdqKTTThtqm4Meta || {},
    component: () => import("/codebuild/output/src3218880673/src/src/pages/menu.vue")
  },
  {
    name: "index",
    path: "/",
    meta: index2xQ4VDVkEIBE8n5QzgGtkuLaiPkrCKYeLmaFV9DErbwMeta || {},
    component: () => import("/codebuild/output/src3218880673/src/src/pages/index.vue")
  },
  {
    name: "contact",
    path: "/contact",
    component: () => import("/codebuild/output/src3218880673/src/src/pages/contact.vue")
  },
  {
    name: "tutorial",
    path: "/tutorial",
    component: () => import("/codebuild/output/src3218880673/src/src/pages/tutorial.vue")
  },
  {
    name: "agreement",
    path: "/agreement",
    component: () => import("/codebuild/output/src3218880673/src/src/pages/agreement.vue")
  },
  {
    name: "about-pass",
    path: "/about-pass",
    component: () => import("/codebuild/output/src3218880673/src/src/pages/about-pass.vue")
  },
  {
    name: "card",
    path: "/card",
    component: () => import("/codebuild/output/src3218880673/src/src/pages/card/index.vue")
  },
  {
    name: "coupon-id",
    path: "/coupon/:id()",
    component: () => import("/codebuild/output/src3218880673/src/src/pages/coupon/[id].vue")
  },
  {
    name: "maintenance",
    path: "/maintenance",
    component: () => import("/codebuild/output/src3218880673/src/src/pages/maintenance.vue")
  },
  {
    name: "notice-id",
    path: "/notice/:id()",
    component: () => import("/codebuild/output/src3218880673/src/src/pages/notice/[id].vue")
  },
  {
    name: "coupon",
    path: "/coupon",
    meta: indexdn6fIm5yrxzRxX2_45mIETeeyR79yMPjNglVlklhvYeLsMeta || {},
    component: () => import("/codebuild/output/src3218880673/src/src/pages/coupon/index.vue")
  },
  {
    name: "mypage",
    path: "/mypage",
    meta: indexjrpLuKIUim7XIo34JzIPR_45hKRSbjS68qBsRVAD6suoAMeta || {},
    component: () => import("/codebuild/output/src3218880673/src/src/pages/mypage/index.vue")
  },
  {
    name: "notice",
    path: "/notice",
    meta: indexiLYKwE6rHgbANUThZUG3ZJFar9_vdWsHtUqrDelWJDcMeta || {},
    component: () => import("/codebuild/output/src3218880673/src/src/pages/notice/index.vue")
  },
  {
    name: "parking-id",
    path: "/parking/:id()",
    meta: _91id_93pFDGXjpQNJ0niWn9j_458HioPobGECfbT6rqPxeJ_45NpLwMeta || {},
    component: () => import("/codebuild/output/src3218880673/src/src/pages/parking/[id].vue")
  },
  {
    name: "history",
    path: "/history",
    meta: indexxTjBrK8Wd3vmTI82XMGpHIfiEYXyuo1KHi_111rWxJcMeta || {},
    component: () => import("/codebuild/output/src3218880673/src/src/pages/history/index.vue")
  },
  {
    name: "sign-in",
    path: "/sign-in",
    meta: indexQZ3MxADR12V9J4EjRYkUyVwl83xnQEr8Y_Izbtw32z4Meta || {},
    component: () => import("/codebuild/output/src3218880673/src/src/pages/sign-in/index.vue")
  },
  {
    name: "sign-up",
    path: "/sign-up",
    meta: indexaA74HXRlb8PkfLw5BwBXdkCOUS0tFvPur_45c8b5VmCUYMeta || {},
    component: () => import("/codebuild/output/src3218880673/src/src/pages/sign-up/index.vue")
  },
  {
    name: "about-yumenavi",
    path: "/about-yumenavi",
    component: () => import("/codebuild/output/src3218880673/src/src/pages/about-yumenavi.vue")
  },
  {
    name: "card-id-edit",
    path: "/card/:id()/edit",
    meta: editgzm412tAYVpRw3Yz4UHuRjCEH8zy30HNqJu_20Wu7C4Meta || {},
    component: () => import("/codebuild/output/src3218880673/src/src/pages/card/[id]/edit.vue")
  },
  {
    name: "pass-agreement",
    path: "/pass-agreement",
    component: () => import("/codebuild/output/src3218880673/src/src/pages/pass-agreement.vue")
  },
  {
    name: "card-id",
    path: "/card/:id()",
    component: () => import("/codebuild/output/src3218880673/src/src/pages/card/[id]/index.vue")
  },
  {
    name: "card-id-confirm",
    path: "/card/:id()/confirm",
    meta: confirmPcj0sIhh_45wai_mAlYfU_45QxhWxITWsargWYZCPZuYOvgMeta || {},
    component: () => import("/codebuild/output/src3218880673/src/src/pages/card/[id]/confirm.vue")
  },
  {
    name: "card-create",
    path: "/card/create",
    meta: indexESJotP13XX4ptOg4x3vFz4rHmMU64NCZ2Zj2X_QU90wMeta || {},
    component: () => import("/codebuild/output/src3218880673/src/src/pages/card/create/index.vue")
  },
  {
    name: "mypage-pass",
    path: "/mypage/pass",
    meta: indexjpI6mgbf5IU2kH5U6LtwZzTlr53t6aYpewXfV0_45swEsMeta || {},
    component: () => import("/codebuild/output/src3218880673/src/src/pages/mypage/pass/index.vue")
  },
  {
    name: "membership-confirm",
    path: "/membership/confirm",
    meta: confirmRkaVjuOf1_457VAXnCFYC9Z06LemUlhrAQD75Wsorgo64Meta || {},
    component: () => import("/codebuild/output/src3218880673/src/src/pages/membership/confirm.vue")
  },
  {
    name: "card-create-confirm",
    path: "/card/create/confirm",
    meta: confirm1y0OjPF4QPTgEHpS5J0DwAYzZPiCDMqFMFSpoLJwZDEMeta || {},
    component: () => import("/codebuild/output/src3218880673/src/src/pages/card/create/confirm.vue")
  },
  {
    name: "membership-complete",
    path: "/membership/complete",
    meta: completeRl9GqGtvvgFCFfuzfHyzabVesjyYmyU6Fqi_LEEy6JwMeta || {},
    component: () => import("/codebuild/output/src3218880673/src/src/pages/membership/complete.vue")
  },
  {
    name: "membership-register",
    path: "/membership/register",
    meta: registerHGQkuOHYp_MRvjwnWJeKLM_45EtcHXl3rbaETMFC_45PO8UMeta || {},
    component: () => import("/codebuild/output/src3218880673/src/src/pages/membership/register.vue")
  },
  {
    name: "monthly-parkingId",
    path: "/monthly/:parkingId()",
    component: () => import("/codebuild/output/src3218880673/src/src/pages/monthly/[parkingId].vue")
  },
  {
    name: "contact-form-confirm",
    path: "/contact-form/confirm",
    meta: confirmyMxc9v3fyOZLb0xdb0indLCfMNvO17G3m_xIWOmv6IcMeta || {},
    component: () => import("/codebuild/output/src3218880673/src/src/pages/contact-form/confirm.vue")
  },
  {
    name: "history-receipt-id",
    path: "/history/receipt/:id()",
    component: () => import("/codebuild/output/src3218880673/src/src/pages/history/receipt/[id].vue")
  },
  {
    name: "sign-in-confirm-code",
    path: "/sign-in/confirm-code",
    meta: confirm_45codexoaxz4KJ9yznq0bUNrMZ1U9fjIgbZGP0Hhc1WHxtkQsMeta || {},
    component: () => import("/codebuild/output/src3218880673/src/src/pages/sign-in/confirm-code.vue")
  },
  {
    name: "sign-up-confirm-code",
    path: "/sign-up/confirm-code",
    meta: confirm_45codekvrh7mR5iSX7aewVcIxbU_45Dq_45vvN09iELNOolJ2iyFoMeta || {},
    component: () => import("/codebuild/output/src3218880673/src/src/pages/sign-up/confirm-code.vue")
  },
  {
    name: "contact-form-complete",
    path: "/contact-form/complete",
    meta: completeewfumv2iry52QE1djYuW4nuUBg6uLL1iDVlR_gm2vckMeta || {},
    component: () => import("/codebuild/output/src3218880673/src/src/pages/contact-form/complete.vue")
  },
  {
    name: "contact-form-register",
    path: "/contact-form/register",
    meta: registerILTgEMUNLqpjA0UiVMFRn4dCz1MIQal2bVCGCvMSl18Meta || {},
    component: () => import("/codebuild/output/src3218880673/src/src/pages/contact-form/register.vue")
  },
  {
    name: "mypage-membership",
    path: "/mypage/membership",
    component: () => import("/codebuild/output/src3218880673/src/src/pages/mypage/membership/index.vue")
  },
  {
    name: "mypage-sign-out-confirm",
    path: "/mypage/sign-out/confirm",
    meta: confirmzoQFnCjve8ilHqvw_PSnySRmh7inKvSWVcENKHO4ATEMeta || {},
    component: () => import("/codebuild/output/src3218880673/src/src/pages/mypage/sign-out/confirm.vue")
  },
  {
    name: "reset-password-complete",
    path: "/reset-password/complete",
    meta: complete_456f2mdd_LSiQIb3QjUaIPn4ESI7HlUfsoFJNYj0o72wMeta || {},
    component: () => import("/codebuild/output/src3218880673/src/src/pages/reset-password/complete.vue")
  },
  {
    name: "mypage-integration",
    path: "/mypage/integration",
    meta: indexyyn4wb4IAckioCUoWi9wNHsKFXLF6RRinwgl6kIaBX8Meta || {},
    component: () => import("/codebuild/output/src3218880673/src/src/pages/mypage/integration/index.vue")
  },
  {
    name: "mypage-pass-receipt-id",
    path: "/mypage/pass/receipt/:id()",
    component: () => import("/codebuild/output/src3218880673/src/src/pages/mypage/pass/receipt/[id].vue")
  },
  {
    name: "mypage-sign-out-complete",
    path: "/mypage/sign-out/complete",
    meta: completeBuc2InQkhiIGgMhksRNT7HHI30hbkIautmGjH4tE1tQMeta || {},
    component: () => import("/codebuild/output/src3218880673/src/src/pages/mypage/sign-out/complete.vue")
  },
  {
    name: "card-id-edit-card-alias",
    path: "/card/:id()/edit-card-alias",
    meta: edit_45card_45aliasDng5jyLv46zhLbg2lZaYiOvEW6eAG5P_Gg9q7F_opkMMeta || {},
    component: () => import("/codebuild/output/src3218880673/src/src/pages/card/[id]/edit-card-alias.vue")
  },
  {
    name: "mypage-integration-sample",
    path: "/mypage/integration/sample",
    meta: sampleWLyhjbSUoxvJlkFBoWROBdwgB2dYE3Kavo4eihp51WUMeta || {},
    component: () => import("/codebuild/output/src3218880673/src/src/pages/mypage/integration/sample.vue")
  },
  {
    name: "reset-password-send-email",
    path: "/reset-password/send-email",
    meta: send_45emailPDUBWuPEh_45nq7MBEYnpCtNuXN0tOpME1W4V7TqRig_MMeta || {},
    component: () => import("/codebuild/output/src3218880673/src/src/pages/reset-password/send-email.vue")
  },
  {
    name: "mypage-unsubscribe-confirm",
    path: "/mypage/unsubscribe/confirm",
    meta: confirmMnPK7fYnSN3x_5_45TnOCkoZGq_Y_1h59Sjb4JnTezT3cMeta || {},
    component: () => import("/codebuild/output/src3218880673/src/src/pages/mypage/unsubscribe/confirm.vue")
  },
  {
    name: "mypage-unsubscribe-consent",
    path: "/mypage/unsubscribe/consent",
    meta: consenteMUuanPIFT0XfMRhSYKWTx2lwjPNXQXO0pQ3kSxUDpkMeta || {},
    component: () => import("/codebuild/output/src3218880673/src/src/pages/mypage/unsubscribe/consent.vue")
  },
  {
    name: "membership-yumenavi-confirm",
    path: "/membership/yumenavi/confirm",
    meta: confirmlKcCfo99zi3HO0ME2VsflFB3nWgkTlb_45uYVdeWVaLFAMeta || {},
    component: () => import("/codebuild/output/src3218880673/src/src/pages/membership/yumenavi/confirm.vue")
  },
  {
    name: "mypage-unsubscribe-complete",
    path: "/mypage/unsubscribe/complete",
    meta: completebPiV0YLGRNhNWjXDvkpbKxvlE7ILPHl6dYeAggdLNEYMeta || {},
    component: () => import("/codebuild/output/src3218880673/src/src/pages/mypage/unsubscribe/complete.vue")
  },
  {
    name: "reset-password-confirm-code",
    path: "/reset-password/confirm-code",
    meta: confirm_45codeTAvBiqxW6fJxOgrATgfW4lsknUQDveM19JBE7SgFW5sMeta || {},
    component: () => import("/codebuild/output/src3218880673/src/src/pages/reset-password/confirm-code.vue")
  },
  {
    name: "reset-password-new-password",
    path: "/reset-password/new-password",
    meta: new_45passwordZGh6SjSZMtUDjMy5lJlGvGaEUWZhA2ROHmd4MeWkXMcMeta || {},
    component: () => import("/codebuild/output/src3218880673/src/src/pages/reset-password/new-password.vue")
  },
  {
    name: "card-id-confirm-card-alias",
    path: "/card/:id()/confirm-card-alias",
    meta: confirm_45card_45aliasyXhiYnylp68N403llqLbRtdpEllEB7T5BtVwaLhuDoUMeta || {},
    component: () => import("/codebuild/output/src3218880673/src/src/pages/card/[id]/confirm-card-alias.vue")
  },
  {
    name: "membership-yumenavi-register",
    path: "/membership/yumenavi/register",
    meta: registerNqylVWjC2AqRwy6_45Eaxx7lfaDHfj1e1ABaW5JfRSQiUMeta || {},
    component: () => import("/codebuild/output/src3218880673/src/src/pages/membership/yumenavi/register.vue")
  },
  {
    name: "membership-yumenavi-confirm-code",
    path: "/membership/yumenavi/confirm-code",
    meta: confirm_45code4B_8VQrFjshLO4Ohp1nIwYFmheLXcen53PxKSc0nctcMeta || {},
    component: () => import("/codebuild/output/src3218880673/src/src/pages/membership/yumenavi/confirm-code.vue")
  },
  {
    name: "mypage-membership-email-edit",
    path: "/mypage/membership/email-edit",
    component: () => import("/codebuild/output/src3218880673/src/src/pages/mypage/membership/email-edit/index.vue")
  },
  {
    name: "mypage-membership-others-edit",
    path: "/mypage/membership/others-edit",
    component: () => import("/codebuild/output/src3218880673/src/src/pages/mypage/membership/others-edit/index.vue")
  },
  {
    name: "mypage-membership-others-edit-update",
    path: "/mypage/membership/others-edit/update",
    component: () => import("/codebuild/output/src3218880673/src/src/pages/mypage/membership/others-edit/update.vue")
  },
  {
    name: "mypage-membership-email-edit-complete",
    path: "/mypage/membership/email-edit/complete",
    component: () => import("/codebuild/output/src3218880673/src/src/pages/mypage/membership/email-edit/complete.vue")
  },
  {
    name: "mypage-membership-others-edit-confirm",
    path: "/mypage/membership/others-edit/confirm",
    component: () => import("/codebuild/output/src3218880673/src/src/pages/mypage/membership/others-edit/confirm.vue")
  },
  {
    name: "mypage-membership-others-edit-complete",
    path: "/mypage/membership/others-edit/complete",
    component: () => import("/codebuild/output/src3218880673/src/src/pages/mypage/membership/others-edit/complete.vue")
  },
  {
    name: "mypage-membership-password-edit-update",
    path: "/mypage/membership/password-edit/update",
    component: () => import("/codebuild/output/src3218880673/src/src/pages/mypage/membership/password-edit/update.vue")
  },
  {
    name: "mypage-membership-password-edit-complete",
    path: "/mypage/membership/password-edit/complete",
    component: () => import("/codebuild/output/src3218880673/src/src/pages/mypage/membership/password-edit/complete.vue")
  },
  {
    name: "mypage-membership-email-edit-confirm-code",
    path: "/mypage/membership/email-edit/confirm-code",
    component: () => import("/codebuild/output/src3218880673/src/src/pages/mypage/membership/email-edit/confirm-code.vue")
  },
  {
    name: "mypage-membership-others-edit-yumenavi",
    path: "/mypage/membership/others-edit/yumenavi",
    component: () => import("/codebuild/output/src3218880673/src/src/pages/mypage/membership/others-edit/yumenavi/index.vue")
  },
  {
    name: "mypage-membership-others-edit-yumenavi-update",
    path: "/mypage/membership/others-edit/yumenavi/update",
    component: () => import("/codebuild/output/src3218880673/src/src/pages/mypage/membership/others-edit/yumenavi/update.vue")
  },
  {
    name: "mypage-membership-others-edit-yumenavi-confirm",
    path: "/mypage/membership/others-edit/yumenavi/confirm",
    component: () => import("/codebuild/output/src3218880673/src/src/pages/mypage/membership/others-edit/yumenavi/confirm.vue")
  }
]