export type Location = {
  lat: number;
  lng: number;
  heading?: number | null;
};

export const GPSErrorCode = {
  PERMISSION_DENIED: 1,
  POSITION_UNAVAILABLE: 2,
  TIMEOUT: 3,
} as const;

export type GPSErrorCodeType = (typeof GPSErrorCode)[keyof typeof GPSErrorCode];

export type CanGpsAccess = {
  canAccess: boolean;
  errorCode?: GPSErrorCodeType;
};
