import type { ClassValue } from 'clsx';
import { clsx } from 'clsx';
import { twMerge } from 'tailwind-merge';

/**
 * 指定時間処理を待機する
 * @param ms 待つ時間（ミリ秒）
 * @returns
 */
export const sleep = (ms: number) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

/**
 * オブジェクトが空かどうか判定
 * @param obj
 * @returns
 */
export const isEmptyObject = (obj: object): boolean => {
  return Object.keys(obj).length === 0;
};

/**
 * オブジェクトから指定したキーのみで新しいオブジェクトを作成
 */
export const pick = <
  T extends Record<string, unknown>,
  K extends [keyof T, ...(keyof T)[]],
>(
  obj: T,
  ...keys: K
): Pick<T, K[number]> => {
  const result = {} as Pick<T, K[number]>;
  for (const key of keys) {
    if (key in obj) result[key] = obj[key];
  }
  return result;
};

/**
 * URL文字列をパースしてオブジェクトに変換
 * ＠example "/sample/?key1=value1&key2=value2" => { path: "/sample/", queries: { key1: "value1", key2: "value2" } }
 */
export const getPathObject = (fullPath: string) => {
  const urlObj = new URL(`https://example.com${fullPath}`);
  const params = new URLSearchParams(urlObj.search);

  // クエリパラメータをオブジェクト形式に変換
  const queryParams = Array.from(params.entries()).reduce(
    (acc, [key, value]) => {
      acc[key] = value;
      return acc;
    },
    {} as Record<string, string>,
  );
  return { path: urlObj.pathname, queries: queryParams };
};

/**
 * クラス名を結合する
 * @param inputs
 * @returns
 */
export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}
