/**
 * mswのハンドラー定義
 * 新しいエンドポイントが作成されたら
 */
import { rest } from 'msw';
import mockStatic from '@/mocks/mock/staticResolver';
import mockVersion from '@/features/version/mock/versionResolver';
import mockUser from '@/features/user/mock/userResolver';
import mockYumenavi from '@/features/yumenavi/mock/yumenaviResolver';
import mockCard from '@/features/card/mock/cardResolver';
import mockCoupon from '@/features/coupon/mock/couponResolver';
import mockNotice from '@/features/notice/mock/noticeResolver';
import mockParking from '@/features/parking/mock/parkingResolver';
import mockFaq from '@/features/faq/mock/faqResolver';
import mockPayment from '@/features/payment/mock/paymentResolver';
import mockPopup from '@/features/popup/mock/popupResolver';
import mockPush from '@/features/push/mock/pushResolver';
import mockPass from '@/features/pass/mock/passResolver';
import mockContact from '@/features/contact/mock/contactResolver';
import mockMonthly from '@/features/monthly/api/mock/monthlyResolver';

const toVeritrans = (path: string) => {
  return new URL(path, 'https://api.veritrans.co.jp').toString();
};
const toNaviparkStatic = (path: string) => {
  return new URL(path, 'https://static.dev.app.navipark1.com').toString();
};

export const handlers = [
  rest.post(toVeritrans('/4gtoken'), mockCard.getToken),
  rest.get(
    toNaviparkStatic('/agreementContents.json'),
    mockStatic.getAgreements,
  ),
  rest.get('/api/version', mockVersion.get),
  rest.post('/api/users', mockUser.create),
  rest.post('/api/users/fcm', mockUser.registerDeviceInfo),
  rest.get('/api/users/:userId', mockUser.get),
  rest.get('/api/member', mockUser.getMemberInfo),
  rest.put('/api/member', mockUser.updateMemberInfo),
  rest.delete('/api/member', mockUser.deleteMemberInfo),
  rest.get('/api/member/connections', mockUser.getConnections),
  rest.post('/api/member/connections', mockUser.connectAccount),
  rest.delete('/api/member/connections', mockUser.disconnectAccount),
  rest.get('/api/yumenavi/flow', mockYumenavi.getIsConcurrentFlow),
  rest.post('/api/yumenavi/flow', mockYumenavi.startConcurrentFlow),
  rest.delete('/api/yumenavi/flow', mockYumenavi.cancelConcurrentFlow),
  rest.post('/api/yumenavi/phone', mockYumenavi.startPhoneNumberAuth),
  rest.post('/api/yumenavi/phone/verify', mockYumenavi.sendPhoneNumberAuthCode),
  rest.post('/api/yumenavi/member', mockYumenavi.creteMember),
  rest.get('/api/yumenavi/connection', mockYumenavi.getConnection),
  rest.delete('/api/yumenavi/connection', mockYumenavi.disconnectAccount),
  rest.get('/api/yumenavi/point', mockYumenavi.getPoints),
  rest.get('/api/withdrawal', mockUser.getUnsubscribeReasons),
  rest.get('/api/users/:userId/cards', mockCard.getAll),
  rest.post('/api/users/:userId/cards', mockCard.register),
  rest.put('/api/users/:userId/cards', mockCard.update),
  rest.delete('/api/users/:userId/cards', mockCard.deleteCard),
  rest.post('/api/users/:userId/cards/detail', mockCard.getDetail),
  rest.get('/api/coupons', mockCoupon.getPerPage),
  rest.get('/api/coupons/:couponId', mockCoupon.getDetail),
  rest.get(
    '/api/coupons/parkings/:parkingId',
    mockCoupon.getAvailableForParking,
  ),
  rest.get('/api/notices', mockNotice.getPerPage),
  rest.get('/api/notices/important', mockNotice.getImportant),
  rest.get('/api/notices/:noticeId', mockNotice.getDetail),
  rest.get('/api/prefectures', mockParking.getAllPrefectures),
  rest.get(
    '/api/prefectures/:prefectureId/cities',
    mockParking.getAllCitiesInThePrefecture,
  ),
  rest.get(
    '/api/prefectures/:prefectureId/cities/:cityId/parkings',
    mockParking.getAllParkingsInTheCity,
  ),
  rest.get('/api/parkings/search/freeword', mockParking.searchByKeyword),
  rest.get('/api/parkings/search/location', mockParking.searchByLocation),
  rest.get('/api/users/:userId/payments', mockPayment.getPerPage),
  rest.get('/api/users/:userId/payments/:paymentId', mockPayment.getDetail),
  rest.post('/api/payment/confirm', mockPayment.getConfirmDetail),
  rest.post('/api/payment/card', mockPayment.payByCard),
  rest.post('/api/payment/link', mockPayment.payByOtherCash),
  rest.post('/api/payment/pass', mockPayment.payByPass),
  rest.post('/api/payment/free', mockPayment.payForFree),
  rest.get('/api/payment/check', mockPayment.getIsNotChanged),
  rest.get('/api/parkings/:parkingId', mockParking.getParkingDetail),
  rest.get(
    '/api/parkings/:parkingId/garages/:garageId/usage',
    mockParking.getParkingSituation,
  ),
  rest.get('/api/popup', mockPopup.get),
  rest.post('/api/push/:pushId/open', mockPush.open),
  rest.get('/api/faq', mockFaq.getAll),
  rest.get('/api/parkings/:parkingId/passes/:passId', mockPass.getDetail),
  rest.get('/api/member/pass/payments', mockPass.getPaymentsPerPage),
  rest.get(
    '/api/member/pass/payments/:passPaymentId',
    mockPass.getPaymentDetail,
  ),
  rest.get(
    '/api/parkings/:parkingId/member/pass/payments/',
    mockPass.getPassForTheParking,
  ),
  rest.post('/api/payment/passes/:passId/link', mockPass.makePayment),
  rest.post('/api/inquiry/login', mockContact.sendContactForm),
  // 月極駐車場
  rest.get(
    '/api/monthly/parkings/:parkingId',
    mockParking.getMonthlyParkingDetail,
  ),
  rest.get('/api/monthly/applications', mockMonthly.getMonthlyApplications),
];
