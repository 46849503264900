import { MockedRequest, ResponseResolver, restContext } from 'msw';
import { MonthlyApplications } from '../types';

const getMonthlyApplications: ResponseResolver<
  MockedRequest,
  typeof restContext
> = async (req, res, ctx) => {
  return delayedResponse(
    ctx.status(200),
    ctx.json<MonthlyApplications>({
      applications: [
        // {
        //   id: 1,
        //   status: 'PROVISIONAL_APPLICATION_REVIEW',
        //   statusLabel: '仮申込 審査中',
        //   parkingId: 100,
        //   parkingName: '駐車場名',
        //   garageNumber: 1,
        //   appliedAt: '2021-01-01',
        //   requestedStartDate: '2021-01-01',
        //   contractStartDate: '2021-01-01',
        //   contractUpdateDate: '2021-01-01',
        //   canPayInitialPayment: true,
        //   canUpdateContract: true,
        //   canUpdatePaymentMethod: true,
        //   canRenewContract: true,
        //   canCancelContract: true,
        //   hasResubmissionRequests: true,
        //   hasPaymentIssues: true,
        // },
      ],
      pagination: {
        total: 1,
        perPage: 1,
        currentPage: 1,
        lastPage: 1,
        firstPageUrl: 'http://localhost:3000',
        lastPageUrl: 'http://localhost:3000',
        prevPageUrl: 'http://localhost:3000',
        nextPageUrl: 'http://localhost:3000',
      },
    }),
  );
};

const mockMonthly = {
  getMonthlyApplications,
};
export default mockMonthly;
